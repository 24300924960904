export const jobs = [
  {
    title: 'フルスタックエンジニア',
    location: 'Shibuya, Tokyo',
    link: 'https://hrmos.co/pages/macbeeplanet/jobs/2053049830136725504', //placeholder
    content: `
    <p><b>主な業務内容</b></p>
    <p>
        主な業務内容は、3D ADの技術課題の解消と運用です。システムのスケーラビリティ、パフォーマンス、安定性の向上を目指し、設計、開発、運用を行います。2人の小規模開発チームと共に進めていただきます。また、サービスの成長に応じた技術的課題を解決し、フィードバックを基にプロダクトの改善も推進していただきます。
    </p>
    <br/>
    <p><b>具体的な業務</b></p>
    <ul>
        <li>KPI達成に寄与する新機能開発、運用</li>
        <li>システムのスケーラビリティやパフォーマンス、安定性の向上</li>
        <li>開発速度向上のためのDevOpsの促進、開発ルールの議論、整備</li>
        <li>ビジネスチーム、クリエイティブチームの業務効率化課題の発見、改善</li>
        <li>採用、評価制度などの制度設計、議論</li>
        <li>現状のシステム課題を吸い上げた上でのシステムのリアーキテクチャ、技術選定、およびその遂行</li>
    </ul>
    <br/>
    <p><b>主な技術スタック</b></p>
    <ul>
        <li>バックエンド : Golang, Ruby on Rails, GraphQL, gRPC</li>
        <li>フロントエンド : TypeScript, React, GraphQL Relay</li>
        <li>インフラ/ミドルウェア: Kubernetes(GKE), Argo Workflow, Fluentd, PostgreSQL(CloudSQL), Redis(Cloud Memorystore), Istio, GCP Monitoring, Google Cloud Storage, など</li>
        <li>データ基盤 : BigQuery, dbt, Looker Studio</li>
        <li>DevOps: Github Actions, ArgoCD, Skaffold, Kustomize, Terraform</li>
        <li>ドキュメンテーション : Notion</li>
        <li>タスク管理：Notion</li>
        <li>コミュニケーション : Slack, Gather, Gmail</li>
        <li>MLOps: kubeflow, Vertex AI Pipelines</li>
        <li>推論サーバー : Tensorflow Serving</li>
    </ul>
    <br/>
    <p><b>仕事を通して得られること</b></p>
    <ul>
        <li>技術選定、設計、パフォーマンス改善、開発者体験といった自社プロダクトの成長に必要な一連のエンジニアリングスキル</li>
        <li>経験豊富なCTOを含めた成長意欲の高いチームメンバーとの協業経験</li>
        <li>事業・プロダクトをグロースさせる経験</li>
        <li>アジャイルな開発プロセス、モダンな技術環境下での開発経験</li>
    </ul>`
  },
];
